import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import EmployeesAutoComplete from 'components/Employee/EmployeesAutoComplete'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import SalesReport from 'components/Seller/SalesReport'
import monthNames from 'models/MothNames'
import { useHasRole } from 'hooks/useHasRole'
import { useSelector } from 'react-redux'
import StoreSelector from 'components/Store/StoreSelector'
import { useEffect, useState } from 'react'

const SalesReportPage = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const month = +(params.get('month') || new Date().getMonth());
	const year = +(params.get('year') || new Date().getFullYear());
    const displayStoreTable = params.get('displayStoreTable') === 'true';
	const isManager = useHasRole(['Administrator', 'Manager']) // Initialize your RoleService
    const sellerId =  isManager ? params.get('seller') || user.EmployeeIdentifier : user.EmployeeIdentifier;
    const employeeStores = user.EmployeeStores?.toLowerCase()?.split(',');
    const storeId = params.get('storeId') || employeeStores[employeeStores.length - 1];
    

    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999);
    
	const loadCalendar = (month, year, seller, store) => {
		const params = createSearchParams({
            seller: seller ?? sellerId,
            storeId: store ?? storeId,
			month,
			year
		})

		navigate(`/seller/sales-report?${params.toString()}`)
	}

    const saleItemClick = (sale) => {
        if (sale.storeEventId && sale.storeEventId.length > 0) {
            navigate(`/store-event/${sale.storeEventId}/sales/${sale.id}`)
          } else {
            navigate(`/store/${sale.storeId}/sales/${sale.id}`)
          }
    }

    const storeSelectChanged = (store) => {
        loadCalendar(month, year, sellerId, store);
    };

    console.log('SalesReportPage.js', {month, year, sellerId, storeId, startDate, endDate, displayStoreTable});


	return (
		<Box sx={{ padding: 2 }}>
			<Typography variant="h1" sx={{padding: 2}}>Relatório de vendas mensal</Typography>
            <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <IconButton color="primary" onClick={() => (month === 0 ? loadCalendar(11, year - 1) : loadCalendar(month - 1, year))}>
                            <ArrowLeft />
                        </IconButton>
                        <Typography variant="h6">
                            {monthNames[month]} <small>{year}</small>
                        </Typography>
                        <IconButton color="primary" onClick={() => (month === 11 ? loadCalendar(0, year + 1) : loadCalendar(month + 1, year))}>
                            <ArrowRight />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid xs={12} md={4}>
                    <StoreSelector selectedStore={storeId} onSelectStore={storeSelectChanged} />
                </Grid>
                {isManager && (
                    <Grid xs={12} md={4}>
                        <EmployeesAutoComplete css="w-100" title="Vendedor" placeholder="Selecione um vendedor" controlName="sellerId" onChange={(value) => loadCalendar(month, year, value?.id)} />
                    </Grid>
                )}
                <Grid xs={12} md={12}>
                    <SalesReport storeId={storeId} sellerId={sellerId} startDate={startDate} endDate={endDate} displayStoreTotal={isManager} displayStoreTable={displayStoreTable} saleItemClick={saleItemClick} />
                </Grid>
            </Grid>
		</Box>
	)
}

export default SalesReportPage
