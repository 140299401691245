import { AppBar, Box, Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Switch, Toolbar, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SellIcon from '@mui/icons-material/Sell';
import { useSelector } from 'react-redux';
import ExpandableListItem from './ExpandableListItem';
import NavigationBar from './NavigationBar';

const drawerMinWidth = 73;
const drawerWidth = 200;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1',
  //gap: '10px',
}));

const Logo = styled('img')({
  height: '50px',
  flexGrow: 1,
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isMobile }) => ({
  width: !open ? 0 : drawerWidth,
  display: !open ? 'none' : 'block',
  alignItems: 'center',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : theme.spacing(9),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden'
  },
  [theme.breakpoints.up('sm')]: {
    width: !open ? drawerMinWidth : drawerWidth,
    display: 'block',
    flexShrink: 0,
  },
  bottom: 0,
}));

const ToolbarSpacing = styled('div')(({ theme, isMobile }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...(isMobile && {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 2,
  }),
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0) + ' !important',
}));

const VerticalLayout = ({ isMobile, children }) => {
  const [open, setOpen] = useState(true);
  const auth = useSelector((state) => state.auth);
  const name = auth?.user?.given_name || '';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const sx = isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : {};

  return (
    <Root sx={sx}>
      {isMobile && (
          <IconButton data-name="open-icon" sx={{marginLeft: 2}} color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
            <MenuIcon />
          </IconButton>
      )}
      <CustomDrawer anchor='left' variant="permanent" open={open} onClose={handleDrawerClose} isMobile={isMobile}>
        
        <ToolbarSpacing data-name="ToolbarSpacing"  sx={{justifyContent: 'center'}} isMobile={isMobile}>
            {!open && (
              <IconButton sx={{marginLeft: 0}} data-name="open-icon" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                <MenuIcon />
              </IconButton>
            )}
            {open && (
              <>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
                <Logo src="/assets/img/logo.svg" alt="Logo" />
              </>
            )}
          </ToolbarSpacing>
          <NavigationBar open={open} name={name} />
      </CustomDrawer>
      <ContentContainer maxWidth={true}>
        {children}
      </ContentContainer>
    </Root>
  );
};

export default VerticalLayout;
