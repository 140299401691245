import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import VerticalLayout from "../../components/Layout/VerticalLayout";
import { setStores } from "store/slice/store.slice";
import storeService from "services/store.service";
import themeConfig from "../../Configs/themeConfig";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

const IndexPage = ({ children }) => {
    const stores = useSelector((state) => state.stores.list);
    const dispatch = useDispatch();

    const settings = {
        themeColor: 'primary',
        mode: themeConfig.mode,
        contentWidth: themeConfig.contentWidth
      }
      

    const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
    

    useEffect(() => {
        if (stores.length === 0) {
          storeService.availables().then((stores) => {
            dispatch(setStores(stores));
          })
        }
      }, []);
    

    return (
        <VerticalLayout
          isMobile={hidden}
            hidden={hidden}>
            <Outlet></Outlet>
        </VerticalLayout>
    );
};

export default IndexPage;
