import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import TitleBox from "components/Layout/TitleBox";
import useFormatter from "hooks/useFormatter";
import { EventStatus } from "models/EventStatus";
import storeEventService from "services/store-event.service";

const AdditionalServicesList = ({ stores, onClick }) => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        
        const now = new Date();
        const endDate = new Date(now);
        endDate.setDate(endDate.getDate() + 7);
        
		let filter = {
			startDate: now.toISOString().split('T')[0],
            endDate: endDate,
            status: EventStatus.Confirmed,
            adittional: true,
            stores: stores,
			page: 1,
			pageSize: 200,
		};

		storeEventService.search(filter).then((x) => {
            
            const events = x.items.filter(x => x.services.filter(s=> s.isEnabled && s.signed && !s.eventService.required).length > 0);
			
            setEvents(events);
		});
    }, []);

    return (
        <Box>
            <TitleBox title="Adicionais próximos 7 dias" />
        {events && events.map(event => (
            <EventListItem key={event.id} storeEvent={event} onClick={onClick} />
        ))}
        {(!events || events.length === 0) && (
            <Typography variant="body2" component="div" sx={{ color: 'text.secondary', p: 2 }}>
                Nenhum registro foi encontrado
            </Typography>
        )}
        </Box>
    );
};

export default AdditionalServicesList;



const EventListItem = ({ storeEvent, onClick }) => {
    const formatter = useFormatter();

    const name = storeEvent?.bridge?.name?.split(" ")[0] || storeEvent?.contractorName?.split(" ")[0];

    const services = storeEvent?.services.filter(x => x.isEnabled && x.signed && !x.eventService.required);

    return (
        <Box
            sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start',
                p: 1.2, cursor: 'pointer', borderBottom: 1, borderColor: 'divider', '&:hover': { backgroundColor: 'grey.50' }
            }}
            onClick={onClick?.bind(null, storeEvent)}
        >
            <Box>
                <Typography variant="h6" gutterBottom>
                    Chá da {name} - {formatter(storeEvent?.eventDate, 'date')} {formatter(storeEvent?.eventStartAt, 'time')}
                </Typography>
                {services && services.length > 0 && (
                    <Box key={services.id}>
                        <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
                            Adicional:
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                            {services.map(x => x.eventService.name).join(', ')}
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    );
};
