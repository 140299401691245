import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import moment from 'moment'
import settings from 'settings'
import useFormatter from '../../hooks/useFormatter'

export default function CheckoutCounterTable({ checkoutCounters, onClick }) {
  const formater = useFormatter()

  //format date to local time using moment, what to display using format dd/MM/yyyy HH:mm:ss
  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  }

  if(!checkoutCounters) {
    return <Typography variant="body1">Nenhum caixa encontrado</Typography>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Aberto às</TableCell>
          <TableCell>Fechado às</TableCell>
          <TableCell>Suprimento</TableCell>
          <TableCell>Sangria</TableCell>
          <TableCell>Total</TableCell>
          {/* Add more columns as needed */}
        </TableRow>
      </TableHead>
      <TableBody>
        {checkoutCounters.map((counter) => {

          const payments = counter.payments.filter(p => p.paymentMethod !== settings.returnInstallment && p.paymentMethod !== settings.giftCardInstallment);
          const totalFloat = counter.tillFloat;
          const totalDrop = counter.tillDrop;
          const total = payments.reduce((acc, payment) =>  acc + payment.total, 0) - counter.initialCash - totalFloat + totalDrop;

          return (
            <TableRow key={counter.id} onClick={onClick.bind(null, counter)}>
              <TableCell>{formater(counter.openAt, 'dateTime')}</TableCell>
              <TableCell>{counter.closedAt ? formater(counter.closedAt, 'dateTime') : '--'}</TableCell>
              <TableCell>{formater(counter.tillFloat, 'currency')}</TableCell>
              <TableCell>{formater(counter.tillDrop, 'currency')}</TableCell>
              <TableCell>{formater(total, 'currency')}</TableCell>
              {/* Add more cells as needed */}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
