import { Button, IconButton, Stack, Typography, darken, lighten } from "@mui/material";
import { EventStatus, EventStatusColor } from "../../models/EventStatus";
import React, { useEffect, useState } from "react";

import ArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";
import monthNames from "models/MothNames";
import storeEventService from "../../services/store-event.service";
import styled from "@emotion/styled";
import useTimeFormatter from "../../hooks/useTimeFormatter";

function CalendarDisplay({stores, month, year}) {
	const dayNames = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];

	const [calendar, setCalendar] = useState([]);
	const [events, setEvents] = useState([]);
	const timeFormatter = useTimeFormatter();

	useEffect(() => {
		loadCalendar(month, year);
	}, [month, year, stores]);

	const loadCalendar = (month, year) => {
		//month -= 1; // adjust for 0-indexed month
		var firstMonthDay = new Date(year, month, 1);
		var lastMonthDay = new Date(year, month + 1, 0);

		var firstDay = new Date(year, month, 1);
		var lastDay = new Date(year, month + 1, 0);

		firstDay.setDate(
			firstMonthDay.getDay() === 0 ?
				firstMonthDay.getDate() - 6 :
				firstMonthDay.getDate() - firstMonthDay.getDay() + 1);
		lastDay.setDate(
			lastMonthDay.getDay() === 0
				? lastMonthDay.getDate()
				: lastMonthDay.getDate() + (7 - lastMonthDay.getDay())
		);

		const monthCalendar = [];

		var weeks = Math.ceil(
			(lastDay.getTime() - firstDay.getTime()) / 1000 / 60 / 60 / 24 / 7
		);
		let date = new Date(firstDay.setDate(firstDay.getDate() - 1));
		for (var i = 0; i < weeks; i++) {
			let week = [];

			for (var day = 0; day < 7; day++) {
				date = new Date(date.setDate(date.getDate() + 1));
				week.push(new Date(date));
			}

			monthCalendar.push(week);
		}

		setCalendar(monthCalendar);

		let filter = {
			startDate: firstDay,
			endDate: lastDay,
			stores: stores.map((x) => x.id),
			page: 1,
			pageSize: 200,
		};

		storeEventService.search(filter).then((x) => {

			const items = x.items.filter((e) => e.status !== EventStatus.Expired);
			setEvents(items);
		});
	};

	const getEventBridgeName = (storeEvent) => {
		let name = storeEvent.bridge?.name ?? storeEvent.contractorName;

		return name.split(" ")[0];
	};

	const eventsOn = (date) => {
		let eventDate = new Date(date);
		eventDate.setHours(0, 0, 0, 0);

		return events.filter((e) => {
			let eDate = new Date(e.eventDate);
			eDate.setHours(0, 0, 0, 0);
			return eDate.getTime() === eventDate.getTime();
		}).sort((a, b) => a.eventStartAt.localeCompare(b.eventStartAt));
	};

	const GridDay = styled(Grid)(
		({ theme, day }) => `
			color: ${theme.palette.primary.main};
			min-height: 100px;
			border-bottom: 1px solid #dadce0;
			border-left: 1px solid #dadce0;
			padding: 5px 5px 0px 5px;
			display: flex;
			flex-direction: column;
			gap: 1px;
		
			:nth-of-type(7n) {
			border-right: 1px solid #dadce0;
			}
			
			:hover {
				color: ${darken(theme.palette.primary.main, 0.2)};
			}

			h5 {
			color: #70757a;
			font-size: 12px;
			margin: 0;
			text-align: center;
			width: 100%;
			}
			
			
			${isToday(day) && `
			// Add your custom styles for today here
			background-color: red !important;
			color: ${theme.palette.primary.contrastText};
		  `}
		`
	);

	const GridHeader = styled(Grid)(
		({ theme }) => `
        color: ${theme.palette.primary.main};
        border-top: 1px solid #dadce0;
        border-left: 1px solid #dadce0;
                
        min-height: 10px;
        padding: 5px 10px 0px 10px;
    
        :nth-of-type(7n) {
          border-right: 1px solid #dadce0;
        }
        
        h4 {
          color: #70757a;
          font-size: 11px;
          margin: 0;
          text-align: center;
          width: 100%;
          text-transform: uppercase;
        }
    `
	);

	const EventTitle = styled(Typography)(
		({ status, theme }) => ({
			fontSize: '10px',
			margin: 0,
			textAlign: 'center',
			width: '100%',
			
			backgroundColor: status === EventStatus.Postponed ? lighten(EventStatusColor[status], 0.8) : '',
			borderRadius: '5px',			
			// ${status === EventStatus.Postponed && 'background: #ffe1ca;border-radius: 5px;'}

			':before': {
				'content': '""',
				width: '5px',
				height: '5px',
				backgroundColor: EventStatusColor[status],
				borderRadius: '10px',
				position: 'absolute',
				marginLeft: '-8px',
				marginTop: '5px',

			//   @include md {
			//     width: 8px;
			//     height: 8px;
			//     margin-left: -12px;
			//   }
			}
		})
	);

	const EventTitleMultipleStores = styled(Typography)(
		({ status, theme, color }) => ({
			fontSize: '10px',
			margin: 0,
			textAlign: 'center',
			width: '100%',
			
			backgroundColor: color,
			color: 'white',
			borderRadius: '5px',

		})
	);

	const EventLink = styled(Link)(
		({ theme }) => { return `
        color: ${theme.palette.text.primary};
        font-size: 10px;
        text-decoration: none;
    `;}
	);
	
	const DateTitle = styled(Typography)(
		({ theme, day }) => ({			
			borderRadius: '50%',
			textAlign: 'center',
			display: 'block',
			width: '20px !important',			
			height: "20px !important",
			margin: '0 auto',
			backgroundColor: isToday(day) ? '#4CAF50': 'transparent',
			color: isToday(day) ? 'white': theme.palette.text.primary,
			lineHeight: '20px',
			fontSize: '10px'
		
	}));

	//check if date is today
	const isToday = (someDate) => {
		if(!someDate) return false;
		const today = new Date();
		return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
	};

	const getStoreColor = (storeId) => {
		if(!storeId || !stores) return '#fff';
		return stores.find(x => x.id.toLowerCase() === storeId.toLowerCase()).color;
	};

	console.log('stores', stores.find(x=>x.id === '806E0D53-F356-4610-A67E-D621B58D3540'));

	return (
		<Grid container columns={7}>
			{dayNames.map((day, index) => (
				<GridHeader key={`header_day_${index}`} xs={1}>
					<Typography variant="h4">{day}</Typography>
				</GridHeader>
			))}

			{calendar.map((week, index) =>
				week.map((day, i) => (
					<GridDay key={`day_${i}`} xs={1}>
						<DateTitle day={day}>{day.getDate()}</DateTitle>
						{eventsOn(day).map((event) => (
							<EventLink to={`/store-event/${event.id}`} key={event.id}>
							{stores.length > 1 ? (
								<EventTitleMultipleStores status={event.status} color={getStoreColor(event.storeId)}>
									{timeFormatter(event.eventStartAt)}&nbsp;
									{event.status !== EventStatus.Reserved
										? `Chá da ${getEventBridgeName(event)}`
										: `Pré-reserva da ${getEventBridgeName(event)}`}
									{event.status === EventStatus.Postponed && <small>(ADIADO)</small>}
								</EventTitleMultipleStores>
							) : (
								<EventTitle status={event.status}>
									{timeFormatter(event.eventStartAt)}&nbsp;
									{event.status !== EventStatus.Reserved
										? `Chá da ${getEventBridgeName(event)}`
										: `Pré-reserva da ${getEventBridgeName(event)}`}
									{event.status === EventStatus.Postponed && <small>(ADIADO)</small>}
								</EventTitle>
							)
							}
							</EventLink>
						))}
					</GridDay>
				))
			)}
		</Grid>
	);
}

export default CalendarDisplay;
