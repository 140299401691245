import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { EventListItem } from "./EventListItem";
import { EventStatus } from "models/EventStatus";
import TitleBox from "components/Layout/TitleBox";
import storeEventService from "services/store-event.service";

const AdditionalServicePendingList = ({ stores, onClick }) => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        //create endDate adding 14 days to now
        const endDate = new Date(now);
        endDate.setDate(endDate.getDate() + 14);
        
		let filter = {
			startDate: now,
            endDate: endDate,
            status: EventStatus.Confirmed,
            stores: stores,
			page: 1,
			pageSize: 200,
		};

		storeEventService.search(filter).then((x) => {
            
            const events = x.items.filter(x => x.services.filter(s=> s.isEnabled && !s.signed && !s.eventService.required).length > 0);
			setEvents(events);
		});
    }, []);

    return (
        <Box>
            <TitleBox title="Eventos com adicional pendente" />
        {events && events.map(event => (
            <EventListItem key={event.id} storeEvent={event} onClick={onClick} />
        ))}
        {(!events || events.length === 0) && (
            <Typography variant="body2" component="div" sx={{ color: 'text.secondary', p: 2 }}>
                Nenhum registro foi encontrado
            </Typography>
        )}
        </Box>
    );
};

export default AdditionalServicePendingList;

