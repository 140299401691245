import {  Box,  Divider,  List, ListItemText, Stack, styled, Typography } from '@mui/material';
import React from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NavLink } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SellIcon from '@mui/icons-material/Sell';
import ExpandableListItem from './ExpandableListItem';


const CustomDivider = styled(Divider)({
    marginTop: '10px',
    marginBottom: '10px',
  });
  
  const NavLinkStyled = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '5px'
  }));
  
  const BoxStyled = styled(Box)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '5px',
    '& svg': {
      minWidth: '46px',
    },
  }));
  
  const WrappedText  = styled('span')(({ theme }) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden', // Geralmente, textOverflow de 'ellipsis' é usado com overflow 'hidden'
    whiteSpace: 'nowrap',
  }));
  
const NavigationBar = ({open, name}) => {

    const LinkItem = ({ to, icon, text }) => (
        <NavLinkStyled to={to}>
            {icon}
            {open && <Typography variant='body1'>{text}</Typography>}
        </NavLinkStyled>
    );

	return (
		<>
			<Stack sx={{ flexGrow: 1, justifyContent: 'flex-start', alignItems: open ? 'stretch' : 'center' }}>
                <LinkItem to="/" icon={<DashboardIcon />} text="Dashboard" />
                <LinkItem to="/store-event/register" icon={<EventIcon />} text="Novo evento" />
                <CustomDivider />

				<ExpandableListItem text="Loja" icon={<ReceiptIcon />} open={open}>
					<NavLinkStyled to="/store/A0CB9157-82F0-41AE-82F2-1AC3DFFC87D7">{open && <ListItemText primary="204 norte" />}</NavLinkStyled>
					<NavLinkStyled to="/store/806E0D53-F356-4610-A67E-D621B58D3540">{open && <ListItemText primary="Aguas Claras" />}</NavLinkStyled>
				</ExpandableListItem>

                <LinkItem to="/seller/sales-report" icon={<AssessmentIcon />} text="Relatório de vendas" />
                <LinkItem to="/store/A0CB9157-82F0-41AE-82F2-1AC3DFFC87D7/sales/create" icon={<SellIcon />} text="Registrar venda" />
                
                <div style={{flexGrow: 1}}></div>
			    <CustomDivider />
				{open && (
					<BoxStyled>
						<WrappedText>{name}</WrappedText>
					</BoxStyled>
				)}
				<NavLinkStyled to="/logout">
					<ExitToAppIcon />
					{open && <ListItemText primary="Sair" />}
				</NavLinkStyled>
			</Stack>
		</>
	)
}

export default NavigationBar
