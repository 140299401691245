import axios from "axios";
import settings from "../settings";
import { BaseService } from "./base.service";

const apiPath = `${settings.api.url}/store`;

const colors = ["#8e24aa", "#d56000"];

class StoreService extends BaseService {
  constructor() {
    super();
  }


  availables() {
    console.log('apiPath', apiPath);
    return axios
      .get(`${apiPath}/availables`)
      .then((response) => response.data.data.map((store, i) => ({ ...store, id: store.id.toLowerCase(), color: colors[i] })))
      .catch((error) => this.catchCustomError(error));
  }

  paymentMethods(storeId) {
    return axios
      .get(`${apiPath}/${storeId}/payment-methods`)
      .then((response) => response.data.data)
      .catch((error) => this.catchCustomError(error));
  }
}

export default new StoreService();
