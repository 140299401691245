import React, { useState } from 'react';
import { Box, Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Typography } from '@mui/material';
import { BorderBottom, ExpandLess, ExpandMore } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';


const StackStyled = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px'
  }));

const ListStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: `0`,
    background: theme.palette.grey[300],
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > a': {
        padding: '3px 16px 3px 16px',
        textAlign: 'center',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    }
  }));


const ExpandableListItem = ({ text, children, icon, open }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <StackStyled color='primary' onClick={handleClick}>
        {icon && (icon)}
        {open && <Typography variant='body1' sx={{flexGrow: 1}}>{text}</Typography>}
        {open && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </StackStyled>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ListStyled>
          {children}
        </ListStyled>
      </Collapse>
    </>
  );
};

export default ExpandableListItem;
