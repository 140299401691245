import React from 'react';
import {
    Chip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const StoreSelector = ({ stores, selectedStores, onChange }) => {

    console.log('stores', selectedStores);

    return (
        <div>
            {stores.map((store) => (
                <Chip
                    key={store.id}
                    label={store.name}
                    onClick={() => onChange({ target: { value: store.id } })}
                    style={{
                        margin: '5px',
                        backgroundColor: selectedStores.includes(store.id) ? store.color : 'transparent',
                        border: `1px solid ${store.color}`,
                        color: selectedStores.includes(store.id) ? 'white' : 'black',
                    }}
                    icon={selectedStores.includes(store.id) ? <CheckIcon color='white' /> : null}
                />
            ))}
        </div>
    );
};

export default StoreSelector;
